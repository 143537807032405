
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    

import Head from 'next/head'
import { useEffect } from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import dynamic from 'next/dynamic'
import { RecoilRoot } from 'recoil'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'

import theme from '../theme'

import '../assets/global.css'
import { NODE_ENV, SITE_DOMAIN } from '@/const/env'


const WidgetContact = dynamic(() => import('../components/WidgetContact'), { ssr: false })
const GDPR = dynamic(() => import('../components/GDPR'), { ssr: false })

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CustomApp = ({ Component, pageProps }) => {
  const { pathname } = useRouter()
  const { lang } = useTranslation(`main`)
  const isAdminPage = pathname === `/admin`

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  if (isAdminPage) {
    return (
      <Component {...pageProps} />
    )
  }

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <GDPR />
        <CssBaseline />
        <Head>
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, maximum-scale=1, width=device-width' />
          <link rel='shortcut icon' href={'/favicon/favicon.ico'} type='image/x-icon' />
        </Head>
        <DefaultSeo
          defaultOpenGraphImageHeight={600}
          defaultOpenGraphImageWidth={800}
          dangerouslySetAllPagesToNoFollow={NODE_ENV !== 'production'}
          dangerouslySetAllPagesToNoIndex={NODE_ENV !== 'production'}
          titleTemplate={`%s | MÖWE Studio`}
          openGraph={{
            type: 'website',
            locale: lang,
            url: SITE_DOMAIN,
            site_name: 'Moewe.Studio',
          }}
          twitter={{
            handle: '@handle',
            site: '@site',
            cardType: 'summary_large_image',
          }}
        />
        <Component {...pageProps} />
        <WidgetContact />
      </ThemeProvider>
    </RecoilRoot>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
//
// CustomApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

const __Page_Next_Translate__ = CustomApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  